export var ENCRYPTED_CARD_NUMBER = 'encryptedCardNumber';
export var ENCRYPTED_EXPIRY_DATE = 'encryptedExpiryDate';
export var ENCRYPTED_EXPIRY_MONTH = 'encryptedExpiryMonth';
export var ENCRYPTED_EXPIRY_YEAR = 'encryptedExpiryYear';
export var ENCRYPTED_SECURITY_CODE = 'encryptedSecurityCode';
export var ENCRYPTED_PWD_FIELD = 'encryptedPassword';
export var ENCRYPTED_PIN_FIELD = 'encryptedPin';
export var ENCRYPTED_BANK_ACCNT_NUMBER_FIELD = 'encryptedBankAccountNumber';
export var ENCRYPTED_BANK_LOCATION_FIELD = 'encryptedBankLocationId';
export var SF_VERSION = '3.2.5';
export var DEFAULT_CARD_GROUP_TYPES = ['amex', 'mc', 'visa'];
export var IFRAME_TITLE = 'Iframe for secured card data input field';
export var NON_CREDIT_CARD_TYPE_SECURED_FIELDS = ['sepa', 'sepadirectdebit', 'ach', 'giftcard'];
export var DEFAULT_ERROR = 'incomplete field';
export var CSF_FIELDS_ARRAY = [
    ENCRYPTED_CARD_NUMBER,
    ENCRYPTED_EXPIRY_DATE,
    ENCRYPTED_EXPIRY_MONTH,
    ENCRYPTED_EXPIRY_YEAR,
    ENCRYPTED_SECURITY_CODE
];
