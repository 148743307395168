import { __assign } from "tslib";
import { validateHolderName } from './validate';
// Validate whole cardInput component i.e holderName + securedFields
function validateCardInput() {
    var _this = this;
    var holderNameValid = validateHolderName(this.state.data.holderName, this.props.holderNameRequired);
    var sfpValid = this.state.isSfpValid;
    var addressValid = this.props.billingAddressRequired ? this.state.valid.billingAddress : true;
    var koreanAuthentication = this.props.configuration.koreanAuthenticationRequired ? this.state.valid.taxNumber : true;
    var isValid = sfpValid && holderNameValid && addressValid && koreanAuthentication;
    this.setState({ isValid: isValid }, function () {
        _this.props.onChange(_this.state);
    });
}
function handleAddress(address) {
    var setAddress = function (prevState) { return (__assign(__assign({}, prevState), { billingAddress: address.data, valid: __assign(__assign({}, prevState.valid), { billingAddress: address.isValid }) })); };
    this.setState(setAddress, this.validateCardInput);
}
/**
 * Saves the KCP Authentication details in state
 */
function handleKCPAuthentication(data, valid) {
    var setKCP = function (prevState) { return ({
        data: __assign(__assign({}, prevState.data), data),
        valid: __assign(__assign({}, prevState.valid), valid)
    }); };
    this.setState(setKCP, this.validateCardInput);
}
/**
 * Saves the storeDetails in state
 */
function handleOnStoreDetails(storeDetails) {
    this.setState({ storePaymentMethod: storeDetails }, this.validateCardInput);
}
/**
 * Saves the holderName in state
 */
function handleHolderName(e) {
    var _this = this;
    var holderName = e.target.value;
    var setHolderName = function (prevState) { return ({
        data: __assign(__assign({}, prevState.data), { holderName: holderName }),
        errors: __assign(__assign({}, prevState.errors), { holderName: _this.props.holderNameRequired ? !validateHolderName(holderName) : false }),
        valid: __assign(__assign({}, prevState.valid), { holderName: _this.props.holderNameRequired ? validateHolderName(holderName, _this.props.holderNameRequired) : true })
    }); };
    this.setState(setHolderName, this.validateCardInput);
}
function handleInstallments(value) {
    this.setState({ installments: { value: value } }, this.validateCardInput);
}
function handleSecuredFieldsChange(newState) {
    var _this = this;
    var sfState = newState;
    var tempHolderName = sfState.autoCompleteName ? sfState.autoCompleteName : this.state.data.holderName;
    var setSfpData = function (prevState) { return (__assign(__assign({}, prevState), { data: __assign(__assign(__assign({}, _this.state.data), sfState.data), { holderName: tempHolderName }), errors: __assign(__assign({}, _this.state.errors), sfState.errors), valid: __assign(__assign(__assign({}, _this.state.valid), sfState.valid), { holderName: _this.props.holderNameRequired ? validateHolderName(tempHolderName, _this.props.holderNameRequired) : true }), isSfpValid: sfState.isSfpValid })); };
    this.setState(setSfpData, this.validateCardInput);
}
/**
 * Saves the card brand in state
 */
function handleOnBrand(cardInfo) {
    var _this = this;
    this.setState({ brand: cardInfo.brand, hideCVCForBrand: !!cardInfo.hideCVC }, function () {
        _this.props.onBrand(cardInfo);
    });
}
/**
 * Saves the currently focused element in state
 */
function handleFocus(e) {
    var isFocused = e.focus === true;
    this.setState({ focusedElement: e.currentFocusObject });
    if (isFocused) {
        this.props.onFocus(e);
    }
    else {
        this.props.onBlur(e);
    }
}
/**
 * Handler for the select element added in response to the /binLookup call
 */
function handleAdditionalDataSelection(e) {
    var field = e.currentTarget;
    var value = field.getAttribute('data-value');
    this.setState({ additionalSelectValue: value }, this.validateCardInput);
    // Pass brand into SecuredFields
    if (this.state.additionalSelectType === 'brandSwitcher') {
        this.sfp.processBinLookupResponse({ supportedBrands: [value] });
    }
}
export default {
    handleFocus: handleFocus,
    handleAddress: handleAddress,
    handleKCPAuthentication: handleKCPAuthentication,
    handleOnStoreDetails: handleOnStoreDetails,
    handleHolderName: handleHolderName,
    handleInstallments: handleInstallments,
    handleSecuredFieldsChange: handleSecuredFieldsChange,
    handleOnBrand: handleOnBrand,
    handleAdditionalDataSelection: handleAdditionalDataSelection,
    validateCardInput: validateCardInput
};
