import { __assign } from "tslib";
import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import useCoreContext from '../../../core/Context/useCoreContext';
import PersonalDetails from '../PersonalDetails';
import Address from '../Address';
import Checkbox from '../FormFields/Checkbox';
import './OpenInvoice.scss';
export default function OpenInvoice(props) {
    var countryCode = props.countryCode, visibility = props.visibility;
    var i18n = useCoreContext().i18n;
    var showPersonalDetails = visibility.personalDetails !== 'hidden';
    var showBillingAddress = visibility.billingAddress !== 'hidden';
    var showDeliveryAddress = visibility.deliveryAddress !== 'hidden';
    var _a = useState(__assign(__assign({}, props.data), (props.consentCheckbox && { consentCheckbox: false }))), data = _a[0], setData = _a[1];
    var _b = useState({}), errors = _b[0], setErrors = _b[1];
    var _c = useState({}), valid = _c[0], setValid = _c[1];
    var _d = useState('ready'), status = _d[0], setStatus = _d[1];
    this.setStatus = function (newStatus) {
        setStatus(newStatus);
    };
    var personalDetailsRef = useRef(null);
    var billingAddressRef = useRef(null);
    var deliveryAddressRef = useRef(null);
    useEffect(function () {
        var personalDetailsValid = showPersonalDetails ? !!valid.personalDetails : true;
        var billingAddressValid = showBillingAddress ? !!valid.billingAddress : true;
        var includeDeliveryAddress = showDeliveryAddress && !!data.separateDeliveryAddress;
        var deliveryAddressValid = includeDeliveryAddress ? !!valid.deliveryAddress : true;
        var consentCheckboxValid = props.consentCheckbox ? !!valid.consentCheckbox : true;
        var isValid = personalDetailsValid && billingAddressValid && deliveryAddressValid && consentCheckboxValid;
        var newData = __assign(__assign(__assign({}, (showPersonalDetails && { personalDetails: data.personalDetails })), (showBillingAddress && { billingAddress: data.billingAddress })), (includeDeliveryAddress && { deliveryAddress: data.deliveryAddress }));
        props.onChange({ data: newData, isValid: isValid });
    }, [data, valid, errors]);
    var handleFieldset = function (key) { return function (state) {
        setData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[key] = state.data, _a)));
        });
        setValid(function (prevValid) {
            var _a;
            return (__assign(__assign({}, prevValid), (_a = {}, _a[key] = state.isValid, _a)));
        });
    }; };
    var handleSeparateDeliveryAddress = function (e) {
        setData(function (prevData) { return (__assign(__assign({}, prevData), { separateDeliveryAddress: e.target.checked })); });
    };
    var handleConsentCheckbox = function (e) {
        var checked = e.target.checked;
        setData(function (prevData) { return (__assign(__assign({}, prevData), { consentCheckbox: checked })); });
        setValid(function (prevValid) { return (__assign(__assign({}, prevValid), { consentCheckbox: checked })); });
        setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { consentCheckbox: !checked })); });
    };
    this.showValidation = function () {
        if (showPersonalDetails && personalDetailsRef.current)
            personalDetailsRef.current.showValidation();
        if (showBillingAddress && billingAddressRef.current)
            billingAddressRef.current.showValidation();
        if (showDeliveryAddress && deliveryAddressRef.current)
            deliveryAddressRef.current.showValidation();
        setErrors(__assign({}, (props.consentCheckbox && { consentCheckbox: !data.consentCheckbox })));
    };
    return (h("div", { className: "adyen-checkout__open-invoice" },
        showPersonalDetails && (h(PersonalDetails, { data: data.personalDetails, requiredFields: props.personalDetailsRequiredFields, label: "personalDetails", onChange: handleFieldset('personalDetails'), ref: personalDetailsRef, visibility: visibility.personalDetails })),
        showBillingAddress && (h(Address, { allowedCountries: props.allowedCountries, countryCode: countryCode, data: data.billingAddress, label: "billingAddress", onChange: handleFieldset('billingAddress'), ref: billingAddressRef, visibility: visibility.billingAddress })),
        showDeliveryAddress && (h(Checkbox, { label: i18n.get('separateDeliveryAddress'), classNameModifiers: ['separateDeliveryAddress'], name: "separateDeliveryAddress", onChange: handleSeparateDeliveryAddress })),
        showDeliveryAddress && data.separateDeliveryAddress && (h(Address, { allowedCountries: props.allowedCountries, countryCode: countryCode, data: data.deliveryAddress, label: "deliveryAddress", onChange: handleFieldset('deliveryAddress'), ref: deliveryAddressRef, visibility: visibility.deliveryAddress })),
        props.consentCheckbox &&
            props.consentCheckbox({ countryCode: countryCode, data: data, i18n: i18n, errorMessage: !!errors.consentCheckbox, onChange: handleConsentCheckbox }),
        props.showPayButton && props.payButton({ status: status, label: i18n.get('confirmPurchase') })));
}
