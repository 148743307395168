// filter payment methods that we support (that are in the paymentMethods/index dictionary)
export var filterPresent = function (paymentMethod) { return !!paymentMethod; };
// filter payment methods that are available to the user
export var filterAvailable = function (paymentMethod) {
    if (paymentMethod.isAvailable) {
        var timeout = new Promise(function (resolve, reject) { return setTimeout(reject, 1000); });
        return Promise.race([paymentMethod.isAvailable(), timeout]);
    }
    return Promise.resolve(!!paymentMethod);
};
