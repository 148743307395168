import { __extends, __spreadArrays } from "tslib";
import { Component, cloneElement, toChildArray, h } from 'preact';
import classNames from 'classnames';
import './Field.scss';
import Spinner from '../../Spinner';
import Icon from '../../Icon';
var Field = /** @class */ (function (_super) {
    __extends(Field, _super);
    function Field(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { focused: false };
        _this.onFocus = _this.onFocus.bind(_this);
        _this.onBlur = _this.onBlur.bind(_this);
        return _this;
    }
    Field.prototype.onFocus = function (e) {
        var _this = this;
        this.setState({ focused: true }, function () {
            if (_this.props.onFocus)
                _this.props.onFocus(e);
        });
    };
    Field.prototype.onBlur = function (e) {
        var _this = this;
        this.setState({ focused: false }, function () {
            if (_this.props.onBlur)
                _this.props.onBlur(e);
            // When we also need to fire a specific function when a field blurs
            if (_this.props.onFieldBlur)
                _this.props.onFieldBlur(e);
        });
    };
    Field.getDerivedStateFromProps = function (nextProps, prevState) {
        if (nextProps.focused !== undefined && nextProps.focused !== prevState.focused) {
            return { focused: nextProps.focused };
        }
        if (nextProps.filled !== undefined && nextProps.filled !== prevState.filled) {
            return { filled: nextProps.filled };
        }
        return null;
    };
    Field.prototype.render = function (_a) {
        var _this = this;
        var _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.classNameModifiers, classNameModifiers = _c === void 0 ? [] : _c, children = _a.children, errorMessage = _a.errorMessage, helper = _a.helper, _d = _a.inputWrapperModifiers, inputWrapperModifiers = _d === void 0 ? [] : _d, isLoading = _a.isLoading, isValid = _a.isValid, label = _a.label, dualBrandingElements = _a.dualBrandingElements;
        return (h("div", { className: classNames('adyen-checkout__field', className, classNameModifiers.map(function (m) { return "adyen-checkout__field--" + m; }), {
                'adyen-checkout__field--error': errorMessage,
                'adyen-checkout__field--valid': isValid
            }) },
            h("label", { onClick: this.props.onFocusField, className: classNames({
                    'adyen-checkout__label': true,
                    'adyen-checkout__label--focused': this.state.focused,
                    'adyen-checkout__label--filled': this.state.filled,
                    'adyen-checkout__label--disabled': this.props.disabled
                }) },
                typeof label === 'string' && (h("span", { className: classNames({
                        'adyen-checkout__label__text': true,
                        'adyen-checkout__label__text--error': errorMessage
                    }) }, label)),
                typeof label === 'function' && label(),
                helper && h("span", { className: 'adyen-checkout__helper-text' }, helper),
                h("div", { className: classNames(__spreadArrays([
                        'adyen-checkout__input-wrapper'
                    ], inputWrapperModifiers.map(function (m) { return "adyen-checkout__input-wrapper--" + m; }))) },
                    toChildArray(children).map(function (child) {
                        var props = { isValid: isValid, onFocus: _this.onFocus, onBlur: _this.onBlur, isInvalid: !!errorMessage };
                        return cloneElement(child, props);
                    }),
                    isLoading && (h("span", { className: "adyen-checkout-input__inline-validation adyen-checkout-input__inline-validation--loading" },
                        h(Spinner, { size: "small" }))),
                    isValid && !dualBrandingElements && (h("span", { className: "adyen-checkout-input__inline-validation adyen-checkout-input__inline-validation--valid" },
                        h(Icon, { type: "checkmark" }))),
                    errorMessage && (h("span", { className: "adyen-checkout-input__inline-validation adyen-checkout-input__inline-validation--invalid" },
                        h(Icon, { type: "field_error" })))),
                errorMessage && errorMessage.length && (h("span", { className: 'adyen-checkout__error-text', "aria-live": "polite" }, errorMessage)))));
    };
    return Field;
}(Component));
export default Field;
