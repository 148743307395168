import { __extends } from "tslib";
import { Component, h } from 'preact';
import Countdown from '../Countdown';
import Button from '../Button';
import Spinner from '../Spinner';
import { checkPaymentStatus } from '../../../core/Services/payment-status';
import processResponse from '../../../core/ProcessResponse';
import { getImageUrl } from '../../../utils/get-image';
import './QRLoader.scss';
var QRCODE_URL = 'barcode.shtml?barcodeType=qrCode&fileType=png&data=';
var QRLoader = /** @class */ (function (_super) {
    __extends(QRLoader, _super);
    function QRLoader(props) {
        var _this = _super.call(this, props) || this;
        // Retry until getting a complete response from the server or it times out\
        // Changes interval time to 10 seconds after 1 minute (60 seconds)
        _this.statusInterval = function () {
            _this.checkStatus();
            _this.setState({ timePassed: _this.state.timePassed + _this.props.delay });
            if (_this.state.timePassed >= _this.props.throttleTime) {
                _this.setState({ delay: _this.props.throttledInterval });
            }
        };
        _this.redirectToApp = function (url, fallback) {
            if (fallback === void 0) { fallback = function () { }; }
            setTimeout(function () {
                // Redirect to the APP failed
                _this.props.onError(_this.props.type + " App was not found");
                fallback();
            }, 25);
            window.location.assign(url);
        };
        _this.state = {
            buttonStatus: 'default',
            completed: false,
            delay: props.delay,
            expired: false,
            loading: true,
            onError: function () { },
            percentage: 100,
            timePassed: 0
        };
        _this.onTimeUp = _this.onTimeUp.bind(_this);
        _this.onTick = _this.onTick.bind(_this);
        _this.onComplete = _this.onComplete.bind(_this);
        _this.onError = _this.onError.bind(_this);
        _this.checkStatus = _this.checkStatus.bind(_this);
        return _this;
    }
    QRLoader.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, shouldRedirectOnMobile = _a.shouldRedirectOnMobile, url = _a.url;
        var isMobile = window.matchMedia('(max-width: 768px)').matches && /Android|iPhone|iPod/.test(navigator.userAgent);
        var startPolling = function () {
            _this.interval = setInterval(_this.statusInterval, _this.state.delay);
        };
        if (shouldRedirectOnMobile && url && isMobile) {
            this.redirectToApp(url, startPolling);
        }
        else {
            startPolling();
        }
    };
    QRLoader.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (prevState.delay !== this.state.delay) {
            clearInterval(this.interval);
            this.interval = setInterval(this.statusInterval, this.state.delay);
        }
    };
    QRLoader.prototype.componentWillUnmount = function () {
        clearInterval(this.interval);
    };
    QRLoader.prototype.onTick = function (time) {
        this.setState({ percentage: time.percentage });
    };
    QRLoader.prototype.onTimeUp = function () {
        this.setState({ expired: true });
        clearInterval(this.interval);
        return this.props.onError({ type: 'error', props: { errorMessage: 'Payment Expired' } });
    };
    QRLoader.prototype.onComplete = function (status) {
        clearInterval(this.interval);
        this.setState({ completed: true, loading: false });
        this.props.onComplete({
            data: {
                details: { payload: status.props.payload },
                paymentData: this.props.paymentData
            }
        });
        return status;
    };
    QRLoader.prototype.onError = function (status) {
        clearInterval(this.interval);
        this.setState({ expired: true, loading: false });
        this.props.onComplete({
            data: {
                details: { payload: status.props.payload },
                paymentData: this.props.paymentData
            }
        });
        return status;
    };
    QRLoader.prototype.checkStatus = function () {
        var _this = this;
        var _a = this.props, paymentData = _a.paymentData, originKey = _a.originKey, clientKey = _a.clientKey, loadingContext = _a.loadingContext;
        var accessKey = clientKey ? clientKey : originKey;
        return checkPaymentStatus(paymentData, accessKey, loadingContext)
            .then(processResponse)
            .catch(function (response) { return ({ type: 'network-error', props: response }); })
            .then(function (status) {
            switch (status.type) {
                case 'success':
                    return _this.onComplete(status);
                case 'error':
                    return _this.onError(status);
                default:
                    _this.setState({ loading: false });
            }
            return status;
        });
    };
    QRLoader.prototype.render = function (_a, _b) {
        var _this = this;
        var _c = _a.amount, amount = _c === void 0 ? {} : _c, url = _a.url, brandLogo = _a.brandLogo, _d = _a.classNameModifiers, classNameModifiers = _d === void 0 ? [] : _d, countdownTime = _a.countdownTime, i18n = _a.i18n, instructions = _a.instructions, loadingContext = _a.loadingContext, type = _a.type;
        var expired = _b.expired, completed = _b.completed, loading = _b.loading;
        var qrCodeImage = this.props.qrCodeData ? "" + loadingContext + QRCODE_URL + this.props.qrCodeData : this.props.qrCodeImage;
        var finalState = function (image, message) { return (h("div", { className: "adyen-checkout__qr-loader adyen-checkout__qr-loader--result" },
            h("img", { className: "adyen-checkout__qr-loader__icon adyen-checkout__qr-loader__icon--result", src: getImageUrl({ loadingContext: loadingContext, imageFolder: 'components/' })(image), alt: i18n.get(message) }),
            h("div", { className: "adyen-checkout__qr-loader__subtitle adyen-checkout__qr-loader__subtitle--result" }, i18n.get(message)))); };
        if (expired) {
            return finalState('error', 'error.subtitle.payment');
        }
        if (completed) {
            return finalState('success', 'creditCard.success');
        }
        if (loading) {
            return (h("div", { className: "adyen-checkout__qr-loader" },
                brandLogo && h("img", { alt: type, src: brandLogo, className: "adyen-checkout__qr-loader__brand-logo" }),
                h(Spinner, null)));
        }
        var timeToPayString = i18n.get('wechatpay.timetopay').split('%@');
        return (h("div", { className: "\n                    adyen-checkout__qr-loader\n                    adyen-checkout__qr-loader--" + type + "\n                    " + classNameModifiers.map(function (m) { return "adyen-checkout__qr-loader--" + m; }) + "\n                " },
            brandLogo && h("img", { src: brandLogo, alt: type, className: "adyen-checkout__qr-loader__brand-logo" }),
            h("div", { className: "adyen-checkout__qr-loader__subtitle" }, i18n.get('wechatpay.scanqrcode')),
            h("img", { src: qrCodeImage, alt: i18n.get('wechatpay.scanqrcode') }),
            amount && amount.value && amount.currency && (h("div", { className: "adyen-checkout__qr-loader__payment_amount" }, i18n.amount(amount.value, amount.currency))),
            h("div", { className: "adyen-checkout__qr-loader__progress" },
                h("span", { className: "adyen-checkout__qr-loader__percentage", style: { width: this.state.percentage + "%" } })),
            h("div", { className: "adyen-checkout__qr-loader__countdown" },
                timeToPayString[0],
                "\u00A0",
                h(Countdown, { minutesFromNow: countdownTime, onTick: this.onTick, onCompleted: this.onTimeUp }),
                "\u00A0",
                timeToPayString[1]),
            instructions && h("div", { className: "adyen-checkout__qr-loader__instructions" }, i18n.get(instructions)),
            url && (h("div", { className: "adyen-checkout__qr-loader__app-link" },
                h("span", { className: "adyen-checkout__qr-loader__separator__label" }, i18n.get('or')),
                h(Button, { classNameModifiers: ['qr-loader'], onClick: function () { return _this.redirectToApp(url); }, i18n: i18n, label: i18n.get('openApp') })))));
    };
    QRLoader.defaultProps = {
        delay: 2000,
        countdownTime: 15,
        onError: function () { },
        onComplete: function () { },
        throttleTime: 60000,
        throttledInterval: 10000
    };
    return QRLoader;
}(Component));
export default QRLoader;
